$platform-name: 'homestartuk';
$assets-path: "../assets/" ;

// Fonts
@font-face {
    font-family: "Source Sans Pro";
    src: url($assets-path + "SourceSans3-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url($assets-path + "SourceSans3-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url($assets-path + "SourceSans3-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

// Brand Colours
$brand-primary: #500778;
$brand-secondary: #E35205;
$brand-accent-one: #292B2C;
$brand-accent-two: #FFA300;
$brand-accent-three: #00B0B9;
$brand-accent-four: #DB0A5B;
$brand-accent-five: #F5EAEC;
$border-colour: #E6E6E6;
$body-colour: #292B2C;

//// Background Palette
$bg-light: #F6F2F8;
$bg-light-2: #FEF7F3;
$bg-light-3: #F6EBED;
$white: #FFFFFF;
$black: #000000;
$bg-black: #151515;
$orange-line: #EA580C;

// Font Palette
$headings-colour: $brand-primary;
$donate-colour: $brand-accent-two;

// Typography
$font-family-base: "Source Sans Pro", sans-serif;

$font-size-base: 16px;
$font-size-large: 1.25rem;
$font-size-small: 0.875rem;

$line-height-base: 1.5;
$font-weight-base: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$headings-font-weight: 900;
$displays-font-weight: 900;

$font-size-h1: 2.488rem;
$font-size-h2: 39px;
$font-size-h3: 1.728rem;
$font-size-h4: 1.44rem;
$font-size-h5: 1.2rem;
$font-size-h6: 1rem;

$font-mobile-h1: 38.22px;
$font-mobile-h2: 31.85px;
$font-mobile-h3: 26.54px;
$font-mobile-h4: 22.12px;
$font-mobile-h5: 18.43px;

$font-display-1: 7.450rem;
$font-display-2: 5.960rem;
$font-display-3: 4.768rem;
$font-display-4: 3.815rem;

$mobile-display-1: 72.0px;
$mobile-display-2: 51.2px;
$mobile-display-3: 36.0px;
$mobile-display-4: 25.6px;

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 1.5s;
$transition-curve: ease;

// Layout
$post-max-width: 1000px;
$container-max-width: 1600px;
$container-grid-size: 85%;
$site-bleed: 0px;

// Logo
$logo: "logo.svg";
$logo-width: 127.15px;
$logo-height: 80px;
$logo-mobile-width: 95.36px;
$logo-mobile-height: 60px;

// Buttons
$btn-background-colour: $brand-accent-two;
$btn-text-colour: $brand-primary;
$btn-border-radius: 500px;
$btn-border-width: 0;
$btn-padding-x: 15px;
$btn-padding-y: 10px;
$btn-font-weight: $font-weight-bold;
$btn-font-size: $font-size-large;
$btn-hover-style: darken; // darken or lighten the button on hover

// Menu admin
$menu-admin-enabled: false;

// Navigation
$nav-type: flyout; // normal, flyout, fullscreen, mega, inline
$nav-background-colour: $bg-light;

// Top level items
$nav-top-level-item-font-size: 18px;
$nav-top-level-item-colour: $brand-primary;
$nav-top-level-item-hover-colour: $brand-primary;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-chevrons-enabled: true;

// Submenus
$nav-submenu-width: 100%;
$nav-submenu-background-colour: transparent;
$nav-submenu-border-radius: 0;
$nav-submenu-item-colour: $brand-primary;
$nav-submenu-item-font-size: 18px;
$nav-submenu-item-hover-colour: $brand-primary;
$nav-submenu-item-hover-background-colour: transparent;
$nav-submenu-item-padding: 10px 30px 10px 50px;

// Search
$header-search-margin-right: 0;
$header-search-breakpoint: 0;

// Search - button
$header-search-button-width: 24px;
$header-search-button-height: 24px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: 24px;
$header-search-button-icon-colour: $brand-secondary;

// Home features
$home-features-background-colour: transparent;
$home-features-padding-top: 0;
$home-features-padding-bottom: 0;

// Home feeds
$home-feeds-background-colour: transparent;
$home-feeds-padding-top: 0;
$home-feeds-padding-bottom: 0;

// Impact stats
$impact-stats-padding-y: 0;
$impact-stats-background-colour: transparent;
$impact-stats-colour: $brand-primary;
$impact-stats-breakpoint: 0; // When do you want to break the overall layout from row to column?

// Heading
$impact-stats-heading-font-size: $font-size-h2;
$impact-stats-heading-text-align: left; // left, center, right
$impact-stats-heading-margin-bottom: 0;

// Individual stat
$impact-stat-padding: 2rem;

// Figure
$impact-stats-figure-font-size: 3.815rem;

// Summary
$impact-stats-summary-font-size: $font-size-large;

// Quick giving panel
$quick-giving-background-colour: transparent;
$quick-giving-padding: 0;
$quick-giving-donation-amount-figure-font-size: $font-size-h4;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: $white;
$quick-giving-donation-amount-figure-colour: $black;
$quick-giving-donation-amount-border-width: 0;
$quick-giving-donation-amount-selected-background-colour: $brand-accent-two;
$quick-giving-donation-amount-selected-figure-colour: $brand-primary;

// Home quick giving panel
$home-quick-giving-padding-y: 0;
$home-quick-giving-donation-amount-background-colour: $white;
$home-quick-giving-donation-amount-figure-colour: $black;
$home-quick-giving-donation-amount-selected-background-colour: $brand-accent-two;
$home-quick-giving-donation-amount-selected-figure-colour: $brand-primary;
$home-quick-giving-donation-amount-border-width: 0;
$home-quick-giving-donate-btn-background-colour: $brand-accent-two;
$home-quick-giving-donate-btn-colour: $brand-primary;
$home-quick-giving-donate-btn-font-size: $font-size-large;
$home-quick-giving-colour: $white; // This should cover any headings and text you have in the snippet
$home-quick-giving-heading-colour: $white;

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-margin-top: 0;
$carousel-details-style: below; // overlay, adjacent or below
$carousel-details-position-y: center; // top, center, bottom
$carousel-details-position-x: left; // left, center, right
$carousel-details-margin-x: 0;
$carousel-details-background-colour: $bg-light;
$carousel-details-border-radius: 5px;
$carousel-breakpoint: 0;
$carousel-heading-font-size: $font-size-h1;
$carousel-summary-colour: $brand-primary;
$carousel-read-more-background-colour: $brand-primary;
$carousel-read-more-colour: $white;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: false;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots
$carousel-dots-position-y: bottom; // top, bottom
$carousel-dots-position-x: left; // left, center, right
$carousel-dots-padding: 1.5rem 0;
$carousel-dots-colour: $brand-secondary;

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-colour: $brand-primary;
$newsletter-heading-font-size: $font-size-h2;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-button-height: 48px; // Hard code this so you can match it with the inputs
$newsletter-button-background-colour: $brand-primary;
$newsletter-button-colour: $white;

// Footer
$footer-colour: $white;
$footer-link-colour: $white;
$footer-link-hover-colour: $white;
$footer-background-colour: transparent;
$footer-padding-y: 0;

// Card
$card-image-background-colour: transparent;
$card-details-padding: 2rem;
$card-heading-colour: $brand-primary;
$card-heading-font-size: $font-size-h4;
$card-heading-margin-bottom: 1rem;
$card-summary-colour: $body-colour;
$card-footer-background-colour: transparent;
$card-footer-colour: $brand-primary;
$card-footer-padding: 0;

// Card hover state
$card-hover-image-opacity: 1;
$card-hover-details-background-colour: $bg-light-2;

// Social icons
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $white; // A colour, or 'brand'
$social-icons-width: 30px;
$social-icons-height: 30px;
$social-icons-font-size: 26px;
$social-icons-gap: 0;

// Event feed items
$feed-item-event-start-date-enabled: true;
$feed-item-event-location-enabled: false;

// Sidebar
$sidebar-layout: below; // adjacent, below or above - where do you want the sidebar to be positioned on posts?

// Associated lists
$associated-latest-enabled: false;
$associated-related-enabled: true;
$associated-most-read-enabled: false;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 3;
$associated-item-image-enabled: true;
$associated-list-heading-font-size: $font-size-h4;
$associated-item-heading-colour: $headings-colour;
$associated-item-summary-enabled: false;
$associated-item-border: false;

// Context container
$context-container-background-colour: $bg-light;
$context-container-border-radius: 20px;

// Blockquote
$blockquote-background-colour: transparent;
$blockquote-text-align: left; // left, center, right
$blockquote-font-size: $font-size-h2; // Em unit will cascade to children
$blockquote-line-height: 45px;
$blockquote-padding: 0 0 0 5rem;
$blockquote-colour: $brand-primary;

// FAQs
$faq-item-header-background-colour: $bg-light;
$faq-item-header-padding: 1.5rem;
$faq-item-header-font-size: $font-size-large;
$faq-item-btn-background-colour: $brand-accent-two;
$faq-item-btn-chevron-colour: $brand-primary;
$faq-item-hover-btn-background-colour: $brand-accent-two;
$faq-item-hover-btn-chevron-colour: $brand-primary;
